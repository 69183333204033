




















































































































@import '../style.scss';

.footer {
  padding: 1rem 1.5rem 1rem;
}

.main {
  display: flex;
  min-height: calc(100vh - 52px);
  flex-direction: column;
}

.boosty-button {
  background-color: #f15f2c;
  border-color: #f15f2c !important;
  color: #fff;
  font-size: 0.875rem;
  height: 36px;
  width: 146px;
  padding: calc(0.5em - 1px) 1em;
  position: relative;
  border-radius: 0;
  margin-right: 0.5rem;
}

.boosty-button img {
  height: 36px;
}

.neuro-logo {
  width: 54px;
  max-height: 2.3rem;
}

.neuro-logo-text {
  color: $primary;
  font-weight: 500;
}

.section {
  flex: 1;
}

.custom-menu-content {
  padding: 0 20px;
}
.custom-menu-item {
  padding: 10px 0;
}

@media screen and (min-width: 768px) {
  .section {
    padding: 20px 10%;
  }
}

@media screen and (min-width: 1500px) {
  .section {
    padding: 30px 20%;
  }
}
